import React from 'react';
import NoopLink from '../components/link/noop-link';
import { USER_PRIVACY_STATUS_PRIVATE } from '../constants/user-privacy-status';
import { Owner } from '../types';

type PropsWithUser = {
  user: Owner | undefined;
};

function forUserPrivacyType<T extends PropsWithUser>(
  PublicUserComponent: React.ComponentType<T>,
  PrivateUserComponent: React.ComponentType<T> = NoopLink as React.ComponentType<T>,
) {
  return (props: T) => {
    return props.user?.privacyStatus === USER_PRIVACY_STATUS_PRIVATE ? (
      <PrivateUserComponent {...props} />
    ) : (
      <PublicUserComponent {...props} />
    );
  };
}

export default forUserPrivacyType;
