import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getUserBadgeLabel } from '../../services/get-user-badge-label';
import { getUserRole } from '../../services/user-role';
import { Owner } from '../../types';
import Badge from '../badge';

interface Props {
  user: Owner | undefined;
}

const UserBadge: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const role = getUserRole(user);

  const ariaLabel = getUserBadgeLabel({ t, role });

  return role ? <Badge role={role} ariaLabel={ariaLabel} /> : null;
};

export default UserBadge;
