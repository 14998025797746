import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getTPASettingsIsPostPageSplitEnabled } from '@wix/communities-blog-client-common';
import Page, { PageProps } from '../../../common/components/page';
import { useSelector } from '../../../common/components/runtime-context';
import forDevice from '../../../common/hoc/for-device';
import styles from './post-page-layout.scss';

const PostPageLayoutMobile: React.FC<PageProps> = ({
  children,
  ...otherProps
}) => <Page {...otherProps}>{children}</Page>;

PostPageLayoutMobile.propTypes = {
  children: PropTypes.node,
};

const PostPageLayoutDesktop: React.FC<PageProps> = ({
  children,
  className,
  ...otherProps
}) => {
  const isPostPageSplit = useSelector(getTPASettingsIsPostPageSplitEnabled);

  return (
    <Page
      className={classNames(
        styles.container,
        { [styles.postPageSplit]: isPostPageSplit },
        className,
      )}
      {...otherProps}
    >
      {children}
    </Page>
  );
};

PostPageLayoutDesktop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default forDevice(PostPageLayoutMobile, PostPageLayoutDesktop);
